.menu-container {
    width: 95%;
    margin-left:2.5%;
    padding: 1% 0;
}

.menu_item-button {
    width: 100%;
    background-color: rgb(76, 178, 237);
    border: none;
    outline: none;
    text-align: left;
    padding: 2% 4%;
    font-size: 2rem;
    color: white;
    cursor: pointer;
    transition: background-color 0.2s linear;
}

.menu_item-content {
  background-color: rgb(70, 173, 233);
    padding: 0 20px;
    color: white;
    overflow: hidden;
    font-size: 1.25rem;
    transition: max-height 0.2s ease-in-out;
}
  
.menu_item-button:hover, .menu_item-button.is-open {
    background-color: rgb(126,204,243);
}

.accordion-icon {
  float: right;
  transition: transform 0.6s ease;
}
.accordion-title-content{
  float: center;
}

.accordion-title-content p{
  padding-top: 5%;
  text-align: center;
  vertical-align: middle; 
  text-align: center;
}

.rotate {
  transform: rotate(90deg);
}

.accordion_item-image {
  float: left;
  width: 38vh;
  max-width: 105%;
  padding-right: 3%;
}