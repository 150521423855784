
@media (min-width: 700px) {
    .navbar {
        position: relative;
        width: 100%;
        height: 5.5rem;
        background: url(../../media/background.jpg) no-repeat center;
    } 
}
@media (max-width:700px) {
    .navbar {
        position: relative;
        width: 100%;
        height: 4rem;
        background: url(../../media/background.jpg) no-repeat center;
    } 
}

.navbar_navigation {
    display: flex;
    height: 100%;
    align-items: center;
    padding: 0 1rem;
}

.navbar_logo {
    color: white !important;
    text-decoration: none;
    font-size: 1rem;
    padding: 0 1rem;
    text-align: left !important;
}

.navbar_navigation-items li{
    color: white !important;
    text-decoration: none;
    padding: 0 1.5rem;
    font-size: 1.5rem;
    margin-right: 1rem;
}

.navbar_navigation-items ul{
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
}

.navbar_navigation-items li:hover,
.navbar_navigation-items li:active{
    color: rgba(202, 202, 202, 0.753) !important;
}

@media (max-width: 760px) {
    .navbar_navigation-items {
        display: none;
    }
    
}
@media (min-width: 761px) {
    .navbar_toggle-button {
        display: none;
    }
}