
  .hero-image {
  
    min-height: 65vh;
    background: url(../media/hero_image.jpg) center/cover no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .spacer {
    flex: 1;
  }

  .hero-container {
    display: inline-block;
    background: rgba(0, 0, 0, 0.5);
    color: var(--mainWhite);
    padding: 2rem 1rem;
    text-align: center;
    text-transform: capitalize;
    letter-spacing: var(--mainSpacing);
    background: rgba(151, 151, 151, 0.719);
    border: 5px solid rgb(59, 199, 223);
    box-shadow: 4px 4px 17px 0px rgba(0, 0, 0, 0.63);
  }
  
  .hero-text {
    font-size: 6vmin;
    letter-spacing: 0.2em;
    color: rgba(88, 230, 255, 0.986);
    text-shadow: 0px 2px 3px #000000;
    
  }
  