.leaflet-container {
    width: 95%;
    margin: .5%;
    height: 50vh;
    margin-left: 2%;
    z-index: 90;
  }

.list {
    box-sizing: border-box;
    padding-right: 20px;
    height: 18rem;
    overflow-y: scroll;
}
  
.list-item {
    display: flex;
    background: rgba(255, 255, 255, 0.164);
    padding: 10px;
    margin: 7px 0;
    cursor: pointer;
    font-size: 1rem;
    color: white;
    text-align: left;
}

  
.list-container {
  background: rgba(255, 255, 255, 0.171);
  border-right: 1px solid grey;
  color: white;
  padding: 20px;
  margin: 10px;
  margin-right: 0px;
  height: 25rem;

}
.list-container-mobile {
  background: rgba(255, 255, 255, 0.171);
  color: white;
  padding: 0.5rem;
  margin: 0 10px;
}

.selected-item-container {
  color: white;
  background: rgba(255, 255, 255, 0.157);
  margin-bottom: 20px;
  padding: 20px;
  margin: 10px;
  margin-left: -0px;
  height: 25rem;
}

.info-item {
  text-align: left;
  padding: 5px;
}

.info-box {
  background: rgba(255, 255, 255, 0.164);
  font-size: 20px;
  padding-left: 30px;
}