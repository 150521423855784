
.container {
    background: rgba(255, 255, 255, 0.103);
    position: relative;
    margin: 0 auto;
    width: 100%;
    padding-bottom: 5%;
}
  
  .info-text {
    color: white;
    font-size: 20px;
    padding-bottom: 1%;
}

.img-container {
    width: 100%;
    height: auto;
    max-height: 70vh;
    max-height: 30%;
}
  
  /* resize images */
.img-container img {
    width: 100%;
    margin: -15% 0;
    padding-top: 2%;
    overflow: hidden;
}